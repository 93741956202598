.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  font-family: Rubik;
  flex-direction: column;
  background-color: #FAFAFA;
}
.home-main-video {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.home-video {
  right: -1px;
  width: 100%;
  bottom: 0px;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-navbar-info {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.home-container01 {
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  justify-content: space-between;
}
.home-container02 {
  width: auto;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 0px;
  justify-content: space-between;
}
.home-icon {
  fill: rgb(255, 255, 255);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-halfunit);
}
.home-text {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}
.home-container03 {
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  justify-content: space-between;
}
.home-icon02 {
  fill: #ffffff;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-halfunit);
}
.home-text001 {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}
.home-container04 {
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.home-text002 {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  margin-right: var(--dl-space-space-unit);
}
.home-text003 {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  margin-right: var(--dl-space-space-unit);
}
.home-text004 {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  margin-right: var(--dl-space-space-halfunit);
}
.home-text005 {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  margin-right: var(--dl-space-space-halfunit);
}
.home-text006 {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.home-branding {
  width: 120px;
  object-fit: cover;
}
.home-nav {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text008 {
  margin-left: var(--dl-space-space-twounits);
}
.home-text009 {
  margin-left: var(--dl-space-space-twounits);
}
.home-text010 {
  margin-left: var(--dl-space-space-twounits);
}
.home-burger-menu {
  cursor: pointer;
  display: none;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: flex;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container05 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image {
  height: 2rem;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  flex-direction: column;
  justify-content: center;
}
.home-header-container {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 80px;
  max-width: 790px;
  font-style: normal;
  font-weight: 500;
}
.home-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-button {
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-100);
}
.home-button1 {
  color: var(--dl-color-gray-white);
  margin-left: 20px;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: rgba(255, 255, 255, 0);
  padding-bottom: 22px;
}
.home-button-new2 {
  color: #171717 !important;
  margin-left: 20px;
  border-color: #171717;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: transparent;
  padding-top: 16px;
  padding-bottom: 16px;
}
.home-button-new3 {
  color: #ffffff !important;
  border-color: var(--dl-color-primary-100);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-100) !important;
  padding-top: 16px;
  padding-bottom: 16px;
}
.home-steps {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-container07 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-step {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-image01 {
  width: 100%;
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  object-position: left;
}
.home-step1 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-image02 {
  width: 100%;
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  object-position: left;
}
.home-step2 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-image03 {
  width: 100%;
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  object-position: left;
}
.home-step3 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-image04 {
  width: 100%;
  height: var(--dl-size-size-medium);
  min-width: 150px;
  object-fit: cover;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  object-position: left;
}
.home-step4 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-image05 {
  width: 100%;
  height: var(--dl-size-size-medium);
  min-width: 150px;
  object-fit: cover;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  object-position: left;
}
.home-step5 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-image06 {
  width: 100%;
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  object-position: left;
}
.home-statistics {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.home-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.home-stat {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #313133;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 0px;
}
.home-icon14 {
  fill: var(--dl-color-primary-100);
  width: 32px;
  height: 32px;
}
.home-header1 {
  color: #171717;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 43px;
}
.home-caption1 {
  color: #777777;
  font-size: 16px;
  align-self: center;
  text-align: center;
  line-height: 24px;
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #313133;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 0px;
}
.home-icon16 {
  fill: var(--dl-color-primary-100);
  width: 32px;
  height: 32px;
}
.home-header2 {
  color: #171717;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 43px;
}
.home-caption2 {
  color: #777777;
  font-size: 16px;
  align-self: center;
  text-align: center;
  line-height: 24px;
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #313133;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 0px;
}
.home-icon19 {
  fill: var(--dl-color-primary-100);
  width: 32px;
  height: 32px;
}
.home-header3 {
  color: #171717;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 43px;
}
.home-caption3 {
  color: #777777;
  font-size: 16px;
  align-self: center;
  text-align: center;
  line-height: 24px;
}
.home-content1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.home-stat3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #313133;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 0px;
}
.home-icon22 {
  fill: var(--dl-color-primary-100);
  width: 32px;
  height: 32px;
}
.home-header4 {
  color: #171717;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 43px;
}
.home-caption4 {
  color: #777777;
  font-size: 16px;
  align-self: center;
  text-align: center;
  line-height: 24px;
}
.home-stat4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #313133;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 0px;
}
.home-icon24 {
  fill: var(--dl-color-primary-100);
  width: 32px;
  height: 32px;
}
.home-header5 {
  color: #171717;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 43px;
}
.home-caption5 {
  color: #777777;
  font-size: 16px;
  align-self: center;
  text-align: center;
  line-height: 24px;
}
.home-stat5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #313133;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 0px;
}
.home-icon26 {
  fill: var(--dl-color-primary-100);
  width: 32px;
  height: 32px;
}
.home-header6 {
  color: #171717;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 43px;
}
.home-caption6 {
  color: #777777;
  font-size: 16px;
  align-self: center;
  text-align: center;
  line-height: 24px;
}
.home-features1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
  margin-bottom: 0px;
}
.home-text037 {
  color: #171717;
  font-size: 36px;
  align-self: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-separator {
  width: 100px;
  height: 5px;
  align-self: center;
  background-color: var(--dl-color-primary-100);
}
.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  /*padding-top: var(--dl-space-space-fourunits);*/
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-text038 {
  color: rgb(119, 119, 119);
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  padding-left: var(--dl-space-space-twounits);
  margin-top: -8%;
}
.home-features2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
  margin-bottom: -200px;
}
.home-text049 {
  color: #171717;
  font-size: 36px;
  align-self: center;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-separator1 {
  width: 100px;
  height: 5px;
  align-self: center;
  background-color: var(--dl-color-primary-100);
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  /*padding-top: var(--dl-space-space-fourunits);*/
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-text053 {
  color: rgb(119, 119, 119);
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  padding-right: var(--dl-space-space-twounits);
}
.home-features3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
  margin-bottom: -200px;
}
.home-text070 {
  color: rgb(23, 23, 23);
  font-size: 36px;
  align-self: center;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-separator2 {
  width: 100px;
  height: 5px;
  align-self: center;
  background-color: var(--dl-color-primary-100);
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  /*padding-top: var(--dl-space-space-fourunits);*/
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-text074 {
  color: rgb(119, 119, 119);
  font-size: 16px;
  line-height: 1.5;
  padding-left: var(--dl-space-space-twounits);
  margin-top: -8%;
}
.home-pricing-plans {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-header-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header7 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading1 {
  color: #171717;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.home-caption7 {
  color: rgb(119, 119, 119);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  text-align: center;
  line-height: 36px;
}
.home-pricing {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
}
.home-container12 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  background-size: cover;
  justify-content: center;
}
.home-pricing-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  max-height: 630px;
  min-height: 630px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.home-pricing-card:hover {
  transform: scale(1.02);
}
.home-text088 {
  color: #171717;
  font-size: 1.5rem;
  align-self: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.home-container13 {
  height: 65px;
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text089 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-text092 {
  color: #171717;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 700;
}
.home-text093 {
  color: #171717;
  font-size: 1.2rem;
  align-self: center;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: capitalize;
}
.home-text094 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  align-self: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container14 {
  height: 210px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text097 {
  color: rgb(23, 23, 23);
  height: 210px;
  font-size: 14px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-button2 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: auto;
  text-align: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #ececfc;
}
.home-text114 {
  color: var(--dl-color-primary-100);
}
.home-pricing-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 450px;
  max-height: 630px;
  min-height: 630px;
  transition: 0.3s;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-pricing-card1:hover {
  transform: scale(1.02);
}
.home-text117 {
  color: #171717;
  font-size: 1.5rem;
  align-self: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.home-container15 {
  height: 65px;
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text118 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-text121 {
  color: #171717;
  font-size: 3.5rem;
  font-weight: 700;
}
.home-text122 {
  color: #777777;
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-text124 {
  color: #171717;
  font-size: 1.2rem;
  align-self: center;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: capitalize;
}
.home-text125 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  align-self: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container16 {
  height: 210px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text128 {
  color: rgb(23, 23, 23);
  font-size: 14px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-button3 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: auto;
  text-align: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #ececfc;
}
.home-text148 {
  color: var(--dl-color-primary-100);
}
.home-pricing-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  max-height: 630px;
  min-height: 630px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-white);
}
.home-pricing-card2:hover {
  transform: scale(1.02);
}
.home-text151 {
  color: #171717;
  font-size: 1.5rem;
  align-self: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.home-container17 {
  height: 65px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-button4 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
}
.home-text155 {
  color: rgb(23, 23, 23);
  font-size: 1.2rem;
  align-self: center;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: capitalize;
}
.home-text159 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  align-self: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container18 {
  height: 210px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text162 {
  color: rgb(23, 23, 23);
  font-size: 14px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-button5 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: auto;
  text-align: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
}
.home-testimonial {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text186 {
  color: var(--dl-color-gray-black);
  font-size: 32px;
}
.home-container19 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-testimonial-card {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-testimonial1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text190 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image07 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text191 {
  font-size: 24px;
  font-weight: 300;
}
.home-testimonial-card1 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-testimonial2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text194 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image08 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text197 {
  font-size: 24px;
  font-weight: 300;
}
.home-testimonial-card2 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-testimonial3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text200 {
  color: var(--dl-color-gray-500);
  font-size: 16px;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image09 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text203 {
  font-size: 24px;
  font-weight: 300;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-separator3 {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.home-container20 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.home-image10 {
  width: 120px;
  object-fit: cover;
}
.home-text206 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container21 {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.home-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text211 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text212 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text213 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-company-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text214 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text215 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-container22 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text217 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text218 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text220 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon29 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.home-icon31 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
}
.home-icon33 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-text221 {
  align-self: center;
}
@media(max-width: 991px) {
  .home-main-video {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-stat {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-caption1 {
    align-self: center;
  }
  .home-stat1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-caption2 {
    align-self: center;
  }
  .home-stat2 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-caption3 {
    align-self: center;
  }
  .home-content1 {
    padding-top: 0px;
    flex-direction: column;
  }
  .home-stat3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-caption4 {
    align-self: center;
  }
  .home-stat4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-caption5 {
    align-self: center;
  }
  .home-stat5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-caption6 {
    align-self: center;
  }
  .home-features1 {
    align-items: center;
    margin-bottom: -100px;
  }
  .home-container09 {
    flex-direction: column;
  }
  .home-text038 {
    padding-top: var(--dl-space-space-twounits);
  }
  .home-features2 {
    align-items: center;
    margin-bottom: -100px;
  }
  .home-container10 {
    flex-direction: column;
  }
  .home-text053 {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-features3 {
    align-items: center;
    margin-bottom: -100px;
  }
  .home-container11 {
    flex-direction: column;
  }
  .home-text074 {
    padding-top: var(--dl-space-space-twounits);
  }
  .home-pricing-plans {
    gap: var(--dl-space-space-threeunits);
    padding: var(--dl-space-space-threeunits);
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-container12 {
    flex-flow: column;
  }
  .home-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 100%;
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    margin-bottom: 0px;
  }
  .home-text186 {
    text-align: center;
  }
  .home-container19 {
    flex-direction: column;
  }
  .home-footer {
    flex-direction: column;
  }
  .home-container21 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
  .mobile-hidden {
    display: none;
  }
}
@media(max-width: 767px) {
  .home-navbar-info {
    align-items: center;
  }
  .home-container01 {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text002 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text003 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text004 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text005 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text006 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav {
    display: none;
  }
  .home-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text007 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text008 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text009 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text010 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-burger-menu {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-heading {
    font-size: 60px;
  }
  .home-steps {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container07 {
    flex-direction: column;
  }
  .home-step {
    padding: var(--dl-space-space-unit);
  }
  .home-step1 {
    padding: var(--dl-space-space-unit);
  }
  .home-step2 {
    padding: var(--dl-space-space-unit);
  }
  .home-step3 {
    padding: var(--dl-space-space-unit);
  }
  .home-step4 {
    padding: var(--dl-space-space-unit);
  }
  .home-step5 {
    padding: var(--dl-space-space-unit);
  }
  .home-statistics {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-stat {
    gap: var(--dl-space-space-unit);
  }
  .home-header1 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption1 {
    font-size: 14px;
    align-self: center;
    line-height: 21px;
  }
  .home-stat1 {
    gap: var(--dl-space-space-unit);
  }
  .home-header2 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption2 {
    font-size: 14px;
    align-self: center;
    line-height: 21px;
  }
  .home-stat2 {
    gap: var(--dl-space-space-unit);
  }
  .home-header3 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption3 {
    font-size: 14px;
    align-self: center;
    line-height: 21px;
  }
  .home-stat3 {
    gap: var(--dl-space-space-unit);
  }
  .home-header4 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption4 {
    font-size: 14px;
    align-self: center;
    line-height: 21px;
  }
  .home-stat4 {
    gap: var(--dl-space-space-unit);
  }
  .home-header5 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption5 {
    font-size: 14px;
    align-self: center;
    line-height: 21px;
  }
  .home-stat5 {
    gap: var(--dl-space-space-unit);
  }
  .home-header6 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption6 {
    font-size: 14px;
    align-self: center;
    line-height: 21px;
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text037 {
    text-align: center;
  }
  .home-container09 {
    flex-direction: column;
  }
  .home-features2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text049 {
    text-align: center;
  }
  .home-container10 {
    flex-direction: column;
  }
  .home-features3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text070 {
    text-align: center;
  }
  .home-container11 {
    flex-direction: column;
  }
  .home-pricing-plans {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header-container1 {
    gap: var(--dl-space-space-unit);
  }
  .home-header7 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-heading1 {
    font-size: 40px;
  }
  .home-caption7 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-container12 {
    flex-flow: column;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-pricing-card {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-testimonial {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container20 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-logo {
    align-items: center;
  }
  .home-text206 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text206-img {
    margin: 0 auto;
  }
  .home-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .home-container21 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-product-container {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 479px) {
  .home-text001 {
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
  }
  .home-branding {
    padding-top: var(--dl-space-space-unit);
  }
  .home-burger-menu {
    margin-top: var(--dl-space-space-unit);
    padding-top: 0px;
    background-color: var(--dl-color-gray-white);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero-content {
    gap: var(--dl-space-space-threeunits);
  }
  .home-heading {
    font-size: 34px;
  }
  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .home-button {
    text-align: center;
  }
  .home-button1 {
    text-align: center;
  }
  .home-steps {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container08 {
    flex-direction: column;
  }
  .home-stat {
    border-bottom-width: 0px;
  }
  .home-stat1 {
    border-bottom-width: 0px;
  }
  .home-stat2 {
    border-bottom-width: 0px;
  }
  .home-content1 {
    padding-top: 0px;
  }
  .home-stat3 {
    border-bottom-width: 0px;
  }
  .home-stat4 {
    border-bottom-width: 0px;
  }
  .home-stat5 {
    border-bottom-width: 0px;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-features2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-features3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-header7 {
    gap: 0px;
    flex-direction: column;
  }
  .home-heading1 {
    text-align: center;
  }
  .home-container12 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    margin-bottom: 0px;
  }
  .home-testimonial {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-testimonial-card {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-testimonial-card1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-testimonial-card2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-container20 {
    align-items: center;
    flex-direction: column;
  }
  .home-text206 {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-links-container {
    align-items: center;
    flex-direction: column;
  }
  .home-container21 {
    margin-right: 0px;
  }
  .home-container22 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .home-contact {
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text221 {
    text-align: center;
  }
}

.video-container {
  position: relative;
  width: 150%;
  padding-bottom: 56.25%; /* This maintains a 16:9 aspect ratio (9 / 16 = 0.5625 or 56.25%) */
}

.video-player {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 65%;
}

/* react-tel-input overrides */
.react-tel-input .form-control {
  background: none !important;
  width: 100% !important;
  border-radius: 4px !important;
  font-size: 1rem !important;
  height: 40px !important;
}

.phone-wrong {
  border: 1px solid red !important;
}
